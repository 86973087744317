@import "./v";

.dashboard {
	margin: 16px;
	padding: 16px;
	display: grid;
	grid-template-columns: 1fr;
	gap: 20px;
	background-color: white;

	.small-cards-container {
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
		gap: 16px;
		@include vendorPrefix("border-radius", "8px");
	}

	.small-card-container {
		display: flex;
		flex-direction: column;
		@include vendorPrefix("border-radius", "8px");
		padding: 12px;
		background-color: white;
		box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
		@include vendorPrefix("transition", "all 0.2s");

		.small-card {
			display: flex;
			justify-content: space-between;
			align-items: center;

			.small-card-content {
				display: flex;
				flex-direction: column;
				gap: 4px;
			}

			.small-card-title {
				margin: 0;
				font-weight: 500;
				font-size: 12px;
				color: #666;
			}

			.small-card-count {
				margin: 0;
				font-weight: 700;
				font-size: 24px;
				color: #333;
			}

			.small-card-icon {
				font-size: 24px; // Adjust icon size
				color: #333; // Adjust icon color
				@include vendorPrefix("border-radius", "8px");
				background-color: #f0f0f0;
				padding: 12px;
				display: flex;
				align-items: center;
				justify-content: center;
			}
		}

		&:hover {
			border-color: #bfe1fe;
			background: #f6fbfe;
			@include vendorPrefix("transform", "scale(1.02)");
		}

	}

	.charts-container {
		display: grid;
		gap: 16px;

		.chart-section {
			@include vendorPrefix("border-radius", "8px");
			padding: 16px;
			border: solid 2px;
			border-color: #ebf0f4;

			.section-title {
				margin: 0;
				font-weight: 600;
				font-size: 14px;
				margin-bottom: 16px;
			}

			.chart-grid {
				display: grid;
				grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
				gap: 16px;

				.chart-card {
					background-color: white;
					padding: 16px;
					@include vendorPrefix("border-radius", "8px");
					@include vendorPrefix("box-shadow", "0 0 10px rgba(0,0,0,0.12)");
					position: relative;

					
				}

			}
			
		}
	}
}

// ===================================
// Scan Flow v2 Page
// ===================================

#scanflow-v2 {
	.all-tasks {
		padding: 12px;
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		grid-template-rows: repeat(auto-fill, auto);
		grid-row-gap: 12px;
		grid-column-gap: 12px;
		.one {
			height: auto;
			width: 100% !important;
			@include vendorPrefix("border-radius", "6px");
			@include vendorPrefix("transition", "all 0.2s");
			border: solid 2px;
			border-color: #ebf0f4;
			padding: 16px 16px 20px 16px;
			cursor: pointer;
			background: white;
			position: relative;
			.menu-container {
				ul {
					position: absolute;
					top: 32px;
					right: 0;
					background-color: #fff;
					border: solid 1px #a0b2c3;
					@include vendorPrefix("border-radius", "4px");
					overflow: hidden;
					width: 102px;
					li {
						list-style: none;
						border-bottom: solid 1px #f0f0f0;
						cursor: pointer;
						&:hover {
							background-color: #f0f0f0;
						}
						&:last-child {
							border-bottom: none;
						}
					}
				}
			}
			&:hover {
				border-color: #bfe1fe;
				background: #f6fbfe;
				@include vendorPrefix("transform", "scale(1.02)");
			}
		}
	}
}
// ===================================
// Scanflow Details
// ===================================

#scanflow-details {
	background: rgba(255, 255, 255, 0.92);
	width: 100dvw;
	height: 100dvh;
	top: 0;
	left: 0;
	z-index: 999999;
	padding: 20px 0;
	.content {
		@include calc("width", "100% - 240px");
		border: solid 1px #f0f0f0;
		border-right: none;
		overflow: hidden;
		margin-left: auto;
		background-color: white;
		position: relative;
		@include vendorPrefix("border-radius", "12px 0 0 12px");
		@include vendorPrefix("box-shadow", "0 0 40px rgba(0,0,0,0.12)");
		@include vendorPrefix("animation-duration", "0.2s");
		@include vendorPrefix("animation-fill-mode", "both");
		@include vendorPrefix("animation-name", "fadeInRight");
		@include vendorPrefix(
			"animation-timing-function",
			"cubic-bezier(0.455, 0.03, 0.515, 0.955)"
		);
		.close-btn {
			height: 32px;
			width: 32px;
			background-color: $colorPrimary;
			position: absolute;
			top: 16px;
			left: -16px;
			@include vendorPrefix("border-radius", "4px");
		}
		.preview-area {
			flex: 1;
			padding: 0px 0px 0px 12px;
		}
		.edit-areas {
			width: 318px;
			background-color: #fafafa;
			border-left: solid 1px #eaeaea;
		}
		.wrap {
			@include calc("height", "100dvh - 116px");
			overflow-y: scroll;
		}
		.scanflow-details-left-content {
			@include calc("height", "100dvh - 366px");
		}
		.scanflow-details-right-content {
			@include calc("height", "100dvh - 40px");
			overflow-y: scroll;
		}
		.left-tabs-holder {
			@include calc("width", "100dvw - 740px");
			padding: 0 16px 0 0;
			overflow-x: scroll;
			&::-webkit-scrollbar {
				height: 8px;
			}
			&::-webkit-scrollbar-track {
				background: #f0f0f0;
				@include vendorPrefix("border-radius", "4px");
			}
			&::-webkit-scrollbar-thumb {
				background: #3d404d;
				cursor: grab;
				@include vendorPrefix("border-radius", "4px");
			}
			&::-webkit-scrollbar-thumb:hover {
				background: #aaaaaa;
			}
		}
	}
}

@keyframes fadeInRight {
	0% {
		-webkit-transform: translate3d(400px, 0, 0);
		transform: translate3d(400px, 0, 0);
		opacity: 0;
	}
	100% {
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
		opacity: 1;
	}
}

// ===================================
// Login v2 Page
// ===================================

#login-v2 {
	background-color: white !important;
	height: 100vh;
	video.bg-video {
		width: 100%;
		height: 100%;
		left: 0;
		top: 0;
		z-index: 2;
		position: absolute;
		-o-object-fit: cover;
		object-fit: cover;
		-o-object-position: top center;
		object-position: top center;
		vertical-align: middle;
	}
	.form {
		width: 348px;
		background-color: white;
		border: solid 1px #f0f0f0;
		padding: 20px 28px 28px 28px;
		@include vendorPrefix("border-radius", "8px");
		@include vendorPrefix("box-shadow", "0 0 40px rgba(0,0,0,0.12)");
		position: relative;
		z-index: 3;
		img.logo {
			height: 92px;
			width: auto;
			display: block;
			margin: 0 auto;
		}
		hr {
			height: 80px;
			width: 2px;
			background-color: #f0f0f0;
			margin: 20px auto;
		}
	}
}

// ===================================
// Activities v2 Page
// ===================================

#activity-v2 {
	.activity-cards {
		padding: 0 15px;
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		grid-template-rows: repeat(auto-fill, auto);
		grid-row-gap: 16px;
		grid-column-gap: 16px;
		overflow: hidden;
		.activity-card {
			height: auto;
			width: 100%;
			@include vendorPrefix("border-radius", "6px");
			@include vendorPrefix("transition", "all 0.2s");
			border: solid 2px;
			background-color: white;
			border-color: #ebf0f4;
			padding: 14px 18px 20px 18px;
			position: relative;
			&:hover {
				border-color: #e6f2fc;
			}
			.menu-container {
				ul {
					position: absolute;
					top: 32px;
					right: 0;
					background-color: #fff;
					border: solid 1px #a0b2c3;
					@include vendorPrefix("border-radius", "4px");
					overflow: hidden;
					width: 102px;
					li {
						list-style: none;
						border-bottom: solid 1px #f0f0f0;
						cursor: pointer;
						&:hover {
							background-color: #f0f0f0;
						}
						&:last-child {
							border-bottom: none;
						}
					}
				}
			}
		}
	}
}

#activity-detail-box {
	width: 964px;
	padding: 30px;
	@include vendorPrefix("box-shadow", "0 0 40px rgba(0,0,0,0.13)");
}

// ===================================
// Add Activity v2 Page
// ===================================

#add-activity {
	background-color: white;
	.form-holder {
		width: 964px;
		margin: 0 auto;
		margin-top: 20px;
		margin-bottom: 60px;
		.inner {
			border: solid 1px #f0f0f0;
			padding: 40px;
			@include vendorPrefix("border-radius", "8px");
			background-color: white;
			@include vendorPrefix("box-shadow", "0 0 40px rgba(0,0,0,0.13)");
			table {
				width: 100%;
				display: table;
				border-collapse: collapse;
				tbody {
					tr {
						td {
							vertical-align: top;
							padding-bottom: 8px;
							padding-right: 4px;
							&:nth-child(2n) {
								padding-left: 4px;
							}
						}
						td[colSpan="2"] {
							padding-right: 0;
						}
					}
				}
			}
			.inner-data {
				table {
					width: 100%;
					border-collapse: collapse;
					font-size: 0.875rem;
					border: 1px solid #ddd;
					border-radius: 8px;
					th,
					td {
						padding: 8px;
						text-align: left;
						border-bottom: 1px solid #ddd;
						color: $colorPrimary;
						.badge {
							display: inline-block;
							padding: 4px 8px;
							border: 1px solid #ddd;
							border-radius: 4px;
							color: $colorPrimary;
						}
					}

					th.w-100 {
						width: 100px;
					}

					th.w-60 {
						width: 60px;
					}

					/* Alternate row background */
					// tr:nth-child(even) {
					//   background-color: rgba(0, 0, 0, 0.05);
					// }
				}
			}
		}
	}
}

// ===================================
// =============
// ===================================

// ===================================
// Add Patient v2 Page
// ===================================

#add-patient {
	background-color: white;
	.form-holder {
		width: 964px;
		margin: 0 auto;
		margin-top: 20px;
		margin-bottom: 60px;
		.inner {
			border: solid 1px #f0f0f0;
			padding: 40px;
			@include vendorPrefix("border-radius", "8px");
			background-color: white;
			@include vendorPrefix("box-shadow", "0 0 40px rgba(0,0,0,0.13)");
			table {
				width: 100%;
				display: table;
				border-collapse: collapse;
				tbody {
					tr {
						td {
							vertical-align: top;
							padding-bottom: 8px;
							padding-right: 4px;
							&:nth-child(2n) {
								padding-left: 4px;
							}
						}
						td[colSpan="2"] {
							padding-right: 0;
						}
					}
				}
			}
		}
	}
}

// ===================================
// =============
// ===================================

// ===================================
// Insurance v2 Page
// ===================================

#insurance {
}

// ===================================
// =============
// ===================================

// ===================================
// Add Insurance v2 Page
// ===================================

#add-insurance {
	background-color: white;

	.form-holder {
		width: 964px;
		margin: 0 auto;
		margin-top: 20px;
		margin-bottom: 60px;

		.inner {
			border: solid 1px #f0f0f0;
			padding: 40px;
			@include vendorPrefix("border-radius", "8px");
			background-color: white;
			@include vendorPrefix("box-shadow", "0 0 40px rgba(0,0,0,0.13)");

			table {
				width: 100%;
				display: table;
				border-collapse: collapse;

				tbody {
					tr {
						td {
							vertical-align: top;
							padding-bottom: 8px;
							padding-right: 4px;

							&:nth-child(2n) {
								padding-left: 4px;
							}
						}

						td[colSpan="2"] {
							padding-right: 0;
						}
					}
				}
			}
			.contect-date {
				table {
					width: 100%;
					border-collapse: collapse;
					font-size: 0.875rem;
					border: 1px solid #ddd;
					border-radius: 8px;

					th,
					td {
						padding: 8px;
						text-align: left;
						border-bottom: 1px solid #ddd;
						color: $colorPrimary;
						.badge {
							display: inline-block;
							padding: 4px 8px;
							border: 1px solid #ddd;
							border-radius: 4px;
							color: $colorPrimary;
						}
					}

					th.w-100 {
						width: 100px;
					}

					th.w-60 {
						width: 60px;
					}

					/* Alternate row background */
					// tr:nth-child(even) {
					//   background-color: rgba(0, 0, 0, 0.05);
					// }
				}
			}
		}
	}
}

// ===================================
// =============
// ===================================

// ===================================
// Insurance v2 Page
// ===================================

#insurance {
}

// ===================================
// =============
// ===================================

// ===================================
// Add Insurance v2 Page
// ===================================

#add-insurance {
	background-color: white;
	.form-holder {
		width: 964px;
		margin: 0 auto;
		margin-top: 20px;
		margin-bottom: 60px;
		.inner {
			border: solid 1px #f0f0f0;
			padding: 40px;
			@include vendorPrefix("border-radius", "8px");
			background-color: white;
			@include vendorPrefix("box-shadow", "0 0 40px rgba(0,0,0,0.13)");
			table {
				width: 100%;
				display: table;
				border-collapse: collapse;
				tbody {
					tr {
						td {
							vertical-align: top;
							padding-bottom: 8px;
							padding-right: 4px;

							&:nth-child(2n) {
								padding-left: 4px;
							}
						}

						td[colSpan="2"] {
							padding-right: 0;
						}
					}
				}
			}

			.contect-date {
				table {
					width: 100%;
					border-collapse: collapse;
					font-size: 0.875rem;
					border: 1px solid #ddd;
					border-radius: 8px;

					th,
					td {
						padding: 8px;
						text-align: left;
						border-bottom: 1px solid #ddd;
						color: $colorPrimary !important;

						.badge {
							display: inline-block;
							padding: 4px 8px;
							border: 1px solid #ddd;
							border-radius: 4px;
							color: $colorPrimary !important;
						}
					}

					th.w-100 {
						width: 100px;
					}

					th.w-60 {
						width: 60px;
					}
				}
			}
		}
	}
}

// ===================================
// Add Physician Assistant v2 Page
// ===================================

#add-physician-assistant {
	background-color: white;
	.form-holder {
		width: 964px;
		margin: 0 auto;
		margin-top: 20px;
		margin-bottom: 60px;
		.inner {
			border: solid 1px #f0f0f0;
			padding: 40px;
			@include vendorPrefix("border-radius", "8px");
			background-color: white;
			@include vendorPrefix("box-shadow", "0 0 40px rgba(0,0,0,0.13)");
			table {
				width: 100%;
				display: table;
				border-collapse: collapse;
				tbody {
					tr {
						td {
							vertical-align: top;
							padding-bottom: 8px;
							padding-right: 4px;
							&:nth-child(2n) {
								padding-left: 4px;
							}
						}
						td[colSpan="2"] {
							padding-right: 0;
						}
					}
				}
			}
			.inner-table-date {
				table {
					width: 100%;
					border-collapse: collapse;
					font-size: 0.875rem;
					border: 1px solid #ddd;
					border-radius: 8px;

					th {
						text-align: left;
						padding: 8px;
						color: $colorPrimary !important;
					}

					td {
						padding: 8px;
						text-align: left;
						border-bottom: 1px solid #ddd;
						color: $colorPrimary !important;
						word-break: break-all;
						.badge {
							display: inline-block;
							padding: 4px 8px;
							border: 1px solid #ddd;
							border-radius: 4px;
							color: $colorPrimary !important;
						}
					}

					th.w-100 {
						width: 100px;
					}

					th.w-60 {
						width: 60px;
					}

					/* Alternate row background */
					// tr:nth-child(even) {
					//   background-color: rgba(0, 0, 0, 0.05);
					// }
				}
			}
		}
	}
}

// ====== Profile Page ====== //

#profile-page {
	padding: 20px;
	.cover-image {
		height: 248px;
		display: flex;
		align-items: flex-end;
		justify-content: flex-end;
		padding: 20px 40px;
	}
	.user-info {
		padding: 0 40px 20px 40px;
		display: flex;
		align-items: flex-end;
		border-bottom: solid 1px #f3f4f6;
		gap: 20px;
		margin-top: -40px;
		.user-picture {
			height: 132px;
			width: 132px;
			border: solid 4px #fafafa;
			.add-picture {
				position: absolute;
				bottom: 0;
				right: 0;
				height: 40px;
				width: 40px;
				background-color: #fafafb;
				svg {
					height: 20px;
					width: auto;
					display: block;
				}
			}
		}
	}
	.about-content {
		background-color: #fafafb;
		padding: 20px;
		display: flex;
		.left {
			width: 268px;
			hr {
				outline: none;
				border: none;
				height: 1px;
				width: 100%;
				margin: 10px 0 10px 0;
				background-color: #f3f4f6;
			}
			ul {
				li {
					list-style: none;
					padding: 15px 15px;
					cursor: pointer;
					&:hover {
						background-color: #f0f0f0;
					}
					margin-bottom: 10px;
				}
				li.active {
					background-color: #e0eeeb;
					color: $colorPrimary;
					font-weight: 700;
				}
			}
		}
		.right {
			@include calc("width", "100% - 268px");
			padding-left: 40px;
		}
	}
}
// =============

// ===================================
// ====== Scheduling =======
// ===================================

#opl-scheduling {
	.rbc-calendar {
		font-family: "Inter", sans-serif !important;
	}
	.rbc-toolbar {
		margin-bottom: 12px;
		.rbc-btn-group {
			display: flex;
			gap: 4px;
			button {
				background-color: #f0f0f0;
				color: $colorPrimary !important;
			}
			button.rbc-active {
				background-color: $colorPrimary;
				color: $colorSecondary !important;
			}
		}
		.rbc-toolbar-label {
			color: $colorPrimary !important;
		}
	}
	.rbc-header {
		a {
			color: $colorPrimary !important;
		}
	}
	.rbc-timeslot-group {
		.rbc-label {
			padding: 12px;
		}
	}
	.rbc-row-segment {
		padding: 2px 0;
	}
	.rbc-event-single {
		padding: 2px 5px;
		@include vendorPrefix("border-radius", "5px");
		padding: 2px 5px;
		text-align: left;
		cursor: pointer;
		width: 100%;
		background-color: $colorPrimary;
		color: #92f5e3;
		font-size: 11px;
	}
	.rbc-event-single-new-event,
	.rbc-event-single-started-event,
	.rbc-event-single-cancelled-event,
	.rbc-event-single-completed-event {
		color: white;
	}
	.rbc-event-single-new-event {
		background-color: #60a6d3;
	}
	.rbc-event-single-started-event {
		background-color: #9c5194;
	}
	.rbc-event-single-cancelled-event {
		background-color: #26a797;
	}
	.rbc-event-single-completed-event {
		background-color: #b4b14e;
	}
	.rbc-date-cell,
	.rbc-date-cell a {
		color: $colorPrimary !important;
	}
	.rbc-today {
		background-color: beige !important;
	}
}

// ===================================
// =============
// ===================================
//============== Steper ========
.config-application-stepper {
	.step {
		width: 155px;
		position: relative;
		&:after {
			position: absolute;
			width: 100%;
			content: "";
			height: 4px;
			background: #cfcfcf;
			bottom: 10px;
			left: 50%;
			margin-left: 12px;
		}
		&:last-child {
			&::after {
				display: none;
			}
		}
	}
	.icon {
		height: 24px;
		width: 24px;
		display: block;
		margin: 0 auto !important;
		svg {
			height: 24px;
			width: 24px;
		}
	}
	.incomplete {
		svg {
			path {
				fill: #cfcfcf;
			}
		}
	}
	.complete {
		svg {
			path {
				fill: $colorPrimary !important;
			}
		}
		&:after {
			background: $colorPrimary !important;
		}
	}
}

//============= Specialties ================//

.all-specialties {
	padding: 0 12px;
	display: grid;
	grid-template-columns: repeat(6, 1fr);
	grid-template-rows: repeat(auto-fill, auto);
	grid-column-gap: 12px;
	grid-row-gap: 12px;
	gap: 12px;
	.single {
		background-color: white;
		@include vendorPrefix("border-radius", "12px");
		border: solid 2px;
		border-color: #f0f0f0;
		padding: 20px 26px 28px 20px;
		.icon {
			height: 86px;
			width: 86px;
			margin: 0 auto;
			background-color: #f0f0f0;
			svg {
				height: 32px;
				width: auto;
				display: block;
				color: white;
			}
		}
		p.name {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			width: 186px;
			margin: 0 auto;
			text-align: center;
		}
		hr {
			margin: 16px auto;
			height: 4px;
			width: 24px;
		}
		.btn-del {
			position: absolute;
			top: 16px;
			right: 16px;
			z-index: 2;
		}
		.btn {
			padding: 10px 18px;
			background-color: #cdddeb;
			@include vendorPrefix("border-radius", "20px");
			cursor: pointer;
		}
		&:hover {
			border-color: #a0b2c3;
		}
	}
}
